import React, {useEffect} from 'react'
import css from './home.module.scss';
import Hero from './components/hero';
import Challenge from './components/challenge';
import PricePool from './components/prizePool';
import CardsSection from './components/cardsSection';
import { useSelector,useDispatch } from 'react-redux'
import { subscribeToChallenges, signup, goto, deletePrivateChallenge } from "../../actions/challengeActions";
import { HcpType } from '../../utils/const';
import { displayFee, pricesHtml, translateGameType, currencySymbol, displayCurrency } from '../../utils/translate'
import { joinDialog } from '../challengeDialogs';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../../utils/scrollToTop';
import YourChallengeCTA from './components/navigation/yourChallengeCTA';
import ContactCTA from './components/contactCTA';

 const ExtracurricularLandingpage = () => {

    const dispatch = useDispatch();

    const extracurricular = useSelector(state => state.challengeReducer.specialChallenges);
    const user = useSelector(state =>  state.userReducer.userInfo);
    const isLoggedIn =  useSelector(state =>  state.userReducer.loggedIn);
    


    const challengeData = mapChallengeData(extracurricular);

    useEffect(() => {      
         dispatch(subscribeToChallenges());   
     },[dispatch])

  
   

    const howToSteps = [
        /* { title: 'At your golf club 🏡⛳', desc: '✏️ Register to WGC Tournament' },*/
        {
          title: 'On www.wgc.gg 📲💻 ',
          desc: '📝 Create a free account online ✏️ Join for free the Challenge in your handicap category',
        },
        {
          title: 'On the golf course 🏡⛳',
          desc: '🏌 Have fun playing Extracurricular Challenge 18 holes Net Stableford',
        },
        {
          title: 'On www.wgc.gg 📲💻',
          desc: '✏️ Enter your score on www.wgc.gg after your round, the same day you played before 10pm CT🕙',
        },
        {
          title: 'On www.wgc.gg 📲💻',
          desc: '🏆 Check the Leaderboard section and wait until the Extracurricular Challenge is finished (10pm / Oct 24th) to see your final position',
        },
      ];     
    

    return (
        <>
        <Helmet>
            <title>EXTRACURRICULAR x WGC | Welcome</title>
            <meta
              name="keywords"
              content="golf, challenge, golf course, golf challenge, extracurricular"
            />
            <meta
              name="description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="author" content="Redox Digital" />
            <meta property="og:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              property="og:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta property="og:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            {/*<!-- Update domain -->*/}
            <meta property="og:url" content="https://wgc.gg/extracurricular" /> {/*<!-- Update domain -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              name="twitter:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="twitter:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            <link rel="icon" href="/images/extracurricular/logos/favicon.svg" type="image/svg+xml" />
        </Helmet>
        <ScrollToTop />
        <Hero
            title={
            <>
                Playing golf physically&nbsp;
                <br />
                while competing digitally
            </>
            }
            home
            subtitle="Play in the club of your choice in real life while measuring yourself & your score, virtually against any other player in the world!"
            source="/images/extracurricular/layouts/home_hero.webp"
            opacity={0.25}
        />
        <YourChallengeCTA />
        <main className={css.home}>
            <section id="challenges" className={css.ourChallenges}>
                <div className={css.titles}>
                    <h2>Our Challenges</h2>
                    <p>
                    Choose the category that suits you the most and join players from all around the
                    world.
                    </p>
                </div>
                <div className={css.challenges}>
                    {challengeData.map((challenge, key) => (
                        <Challenge key={key} {...challenge} user={user} isLoggedIn={isLoggedIn} />
                    ))}
                </div>
            </section>

            <PricePool />
            <CardsSection
                title={'The concept'}
                desc={
                    'World Golf Challenge is the first digital golf platform enabling all levels of amateur players worldwide to compete in virtual Challenges online while playing on real courses.'
                }
                cards={[
                    {
                    icon: './images/extracurricular/pictograms/clipboard.svg',
                    title: 'Join a Challenge',
                    desc: 'Any day, choose a Challenge on the screen',
                    },
                    {
                    icon: './images/extracurricular/pictograms/golf-white.svg',
                    title: 'Go out, play golf',
                    desc: 'Real action on the greens',
                    },
                    {
                    icon: './images/extracurricular/pictograms/pen.svg',
                    title: 'Enter your score & win',
                    desc: 'Lots of fun and great prizes',
                    },
                ]}
                />

                <section id="howtoplay" className={css.howtoplay}>
                        <div className={css.titles}>
                            <h2>How to play</h2>
                            <p>
                            Joining a challenge requires, like World Golf Challenge, some work on your
                            phone or computer.
                            </p>
                        </div>
                        <ol>
                            {howToSteps.map((step, key) => (
                            <li key={key}>
                                <b>{step.title}</b>
                                <span>{step.desc}</span>
                            </li>
                            ))}
                        </ol>
                        <div className={css.steps}>
                            <div className={css.row}></div>
                        </div>
                        </section>
                        <ContactCTA
                          img="/images/extracurricular/layouts/home_excr-cta.jpg"
                          grey
                          title={'Look fresh on the greens'}
                          desc={
                            <>
                              Elevate your wardrobe by visiting our sponsor&apos;s e-shop, <b>Extracurricular</b>,
                              and discover men&apos;s premium golf apparel including polos, hats, shorts, and pants.
                            </>
                          }
                          btnLabel="The shop"
                          btnLink="https://ex-cr.com/?utm_source=wgc"
                        />
            </main>

        </>
    );

}

const dummyChallenges = [
    {
      url: 'https://wgc.gg',
      img: './images/extracurricular/logos/logo_excr.svg',
      title: 'CHALLENGE',
      dates: 'Oct. 14th to Oct. 20th',
      gameType: 'Net Strableford',
      hcp: 'single',
      hcptype: HcpType.SINGLE,
      currentPlayers: 0,
    },
    {
      url: 'https://wgc.gg',
      img: '/images/extracurricular/logos/logo_excr.svg',
      title: 'CHALLENGE',
      dates: 'Oct. 14th to Oct. 20th',
      gameType: 'Net Strableford',
      hcp: 'up to 18',
      hcptype: HcpType.HCP_1_18,
      currentPlayers: 0,
    },
    {
      url: 'https://wgc.gg',
      img: '/images/extracurricular/logos/logo_excr.svg',
      title: 'CHALLENGE',
      dates: 'Oct. 14th to Oct. 20th',
      gameType: 'Net Strableford',
      hcp: 'up to 54',
      hcpType: HcpType.HCP_19_36,
      currentPlayers: 0,
    },
  ];


const mapChallengeData = (extracurricular) => {

    if(!extracurricular || extracurricular.length===0) return dummyChallenges;

    const htpSingle = extracurricular.find(challenge => challenge.hcpType === HcpType.SINGLE);
    const htpUpTo18 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_1_18);
    const htpUpTo54 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_19_36);

    return [
        {
          id: htpSingle.id,
          url: insertExtracurricular(htpSingle?.challengeUrl),
          img: '/images/extracurricular/logos/logo_excr.svg',
          title: 'CHALLENGE HCP SINGLE', //replaceExtracurricular(htpSingle.name),
          dates: htpSingle.dates,
          gameType: translateGameType(htpSingle.gameType),
          hcp: 'single',
          hcpType: HcpType.SINGLE,
          currentPlayers: htpSingle.numberOfParticipants,
          challenge: htpSingle,
        },
        {
          id: htpUpTo18.id,
          url: insertExtracurricular(htpUpTo18?.challengeUrl),
          img: '/images/extracurricular/logos/logo_excr.svg',
          title: 'CHALLENGE HCP UP TO 18', // replaceExtracurricular(htpUpTo18.name),
          dates: htpUpTo18.dates,
          gameType: translateGameType(htpUpTo18.gameType),
          hcp: 'up to 18',
          hcpType: HcpType.HCP_1_18,
          currentPlayers: htpUpTo18.numberOfParticipants,
          challenge: htpUpTo18,
        },
        {
          id: htpUpTo54.id,
          url: insertExtracurricular(htpUpTo54?.challengeUrl),
          img: '/images/extracurricular/logos/logo_excr.svg',
          title: 'CHALLENGE HCP UP TO 54', // replaceExtracurricular(htpUpTo54.name),
          dates: htpUpTo54.dates,
          gameType: translateGameType(htpUpTo54.gameType),
          hcp: 'up to 54',
          hcpType: HcpType.HCP_19_36,
          currentPlayers: htpUpTo54.numberOfParticipants,
          challenge: htpUpTo54,
        },
      ];

};

function insertExtracurricular(url) {
  if(url===undefined) return;
  return url.replace('/lobby', '/extracurricular/lobby');
}

function replaceExtracurricular(title) {
  if(title===undefined) return;
  return title.replace('EXTRACURRICULAR', 'CHALLENGE');
}

export default ExtracurricularLandingpage;