import css from './prizePool.module.scss';

export default function PricePool({ alt }) {
    const prices = [
      { img: '/images/extracurricular/layouts/WGC-EXCR_price_1000.png', title: '1st prize', desc: '1,000 $ GIFT CARD', },
      { img: '/images/extracurricular/layouts/WGC-EXCR_price_500.png', title: '2nd prize', desc: '500 $ GIFT CARD' },
      { img: '/images/extracurricular/layouts/WGC-EXCR_price_250.png', title: '3rd prize', desc: '250 $ GIFT CARD' },
      { img: '/images/extracurricular/layouts/WGC-EXCR_price_50percents.png', title: '50% OFF GIFT CARD', desc: 'For every participant!'},
    ];
    return (
      <section className={`${css.pricePool} ${alt && css.alt}`}>
        <h3>Prize pool</h3>
        <div className={css.prices}>
          {prices.map((p, key) => (
            <div className={css.price} key={key}>
              <img src={p.img} alt={''} width={250} height={250} />
              <div className={css.texts}>
                <b>{p.title}</b>
                <p>{p.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }