import css from "./lobby.module.scss";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Hero from "./components/hero";
import PricePool from "./components/prizePool";
import Button from "./components/navigation/button";
import ContactCTA from "./components/contactCTA";
import Rankings from "./components/rankings";
import { Helmet } from "react-helmet-async";
import {
  subscribeToLobby,
  signup,
  closeLobby,
  leave,
  reportScore,
} from "../../actions/challengeActions";
import { getSortedparticipantsCollection } from "../../selectors/participantsSelector";
import {
  ChallengeStatus,
  ChallengeType,
  ParticipantStatus,
} from "../../utils/const";
import {
  joinDialog,
  goToRegisterDialog,
  scoreDialog,
} from "../challengeDialogs";
import { goToRegister } from "../../actions/dialogActions";
import { translateHcpType, translateGameType } from "../../utils/translate";
import {
  CountdownTimer,
  ExtracurricularStartGameRenderer,
  ExtracurricularEndGameRenderer,
} from "../countdownTimer";
import { Region } from "../../utils/const";
import Moment from "react-moment";
import { PayoutType } from "../../utils/const";
import {Tooltip} from 'react-tooltip';
import ScrollToTop from "../../utils/scrollToTop";

export default function ExtracurricularLobby() {
  const dispatch = useDispatch();
  const prevActiveChallengeRef = useRef();
  const region = Region.AMERICAN;
  const { challengeId } = useParams();

  const challengeIdInt = parseInt(challengeId, 10);
  const user = useSelector((state) => state.userReducer.userInfo);
  const userId = useSelector((state) => state.userReducer.user.id);
  const isLoggedIn = useSelector((state) => state.userReducer.loggedIn);
  const activeChallengeStatus = useSelector(
    (state) => state.challengeReducer.activeChallengeStatus
  );

  const activeChallenge = useSelector((state) =>
    getSortedparticipantsCollection(state, challengeIdInt)
  );
  const activeChallengeUserInfo = getActiveChallengeUserInfo(
    userId,
    activeChallenge
  );

  useEffect(() => {
    if (challengeId) {
      dispatch(subscribeToLobby(challengeId));
    }
  }, [challengeId]);

  useEffect(() => {
    const prevActiveChallenge = prevActiveChallengeRef.current;

    // Conditions from componentDidUpdate
    if (
      prevActiveChallenge === undefined &&
      activeChallenge !== undefined &&
      activeChallenge !== null &&
      activeChallenge.isParticipating === false &&
      activeChallenge.type !== ChallengeType.FINAL &&
      (activeChallenge.status === ChallengeStatus.NOT_STARTED ||
        activeChallenge.status === ChallengeStatus.RUNNING)
    ) {
      let additionalText = "";
      let additionalText2 = "<b>Be able to prove your score (e.i. signed scorecard or federation hcp record sheet)</b>";

      additionalText = activeChallenge.description ?? "";

      if (isLoggedIn) {
        console.log("Join flow logic...");
        joinDialog({
          challengeName: translateHcpType(activeChallenge.hcpType),
          challengeNumber: `Challenge ${translateHcpType(activeChallenge.hcpType)}`,
          additionalText,
          additionalText2,
          startTime: activeChallenge.startTime,
          gameType: translateGameType(activeChallenge.gameType),
          holes: activeChallenge.holes,
          fee: "FREE",
          pricePot: activeChallenge.pricePotAfterCut,
          prices:  pricesHtml(activeChallenge.prices),
          signup: () => dispatch(signup(activeChallenge.id, true)),
        });
      } else {
        console.log("Register flow logic...");
        // Register flow logic...
        goToRegisterDialog({
          challengeName: translateHcpType(activeChallenge.hcpType),
          challengeNumber: `Challenge ${translateHcpType(activeChallenge.hcpType)}`,
          additionalText,
          additionalText2,
          startTime: activeChallenge.startTime,
          gameType: translateGameType(activeChallenge.gameType),
          holes: activeChallenge.holes,
          fee: "FREE",
          pricePot: activeChallenge.pricePotAfterCut,
          prices: pricesHtml(activeChallenge.prices),
          goToRegister: () => dispatch(goToRegister()),
        });
      }
    }

    // Update the ref with the current value for the next render
    prevActiveChallengeRef.current = activeChallenge;
  }, [activeChallenge, isLoggedIn]);

  if (activeChallengeStatus === "NOT_LOADED" || activeChallenge === undefined)
    return <div></div>;
  return (
    <>
      <Helmet>
        <title>Lobby | EXTRACURRICULAR x WGC</title>
        <meta
              name="keywords"
              content="golf, challenge, golf course, golf challenge, extracurricular"
            />
            <meta
              name="description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="author" content="Redox Digital" />
            <meta property="og:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              property="og:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta property="og:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            {/*<!-- Update domain -->*/}
            <meta property="og:url" content="https://wgc.gg/extracurricular" /> {/*<!-- Update domain -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              name="twitter:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="twitter:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            <link rel="icon" href="/images/extracurricular/logos/favicon.svg" type="image/svg+xml" />
      </Helmet>
      <ScrollToTop />
      <Hero
        title={`EXTRACURRICULAR - ${translateHcpType(activeChallenge.hcpType)}`}
        subtitle={`Net Stableford - 18 Holes - ${challengeStatusToText(
          activeChallenge.status
        )}`}
        source={"/images/extracurricular/layouts/lobby_hero.webp"}
        opacity={0.25}
      />
      <main>
        <section className={css.intro}>
          <img
            src={"/images/extracurricular/logos/logo_excr.svg"}
            alt={""}
            width={800}
            height={120}
          />
          <div className={css.texts}>
            <img
              src={"/images/extracurricular/logos/logo_excr.svg"}
              alt={""}
              width={800}
              height={120}
            />

            <h3>Challenge</h3>
            <h3>{translateHcpType(activeChallenge.hcpType)}</h3>
            <p>
              <b>Start time:</b>{" "}
              <Moment
                format={
                  region === Region.EUROPE ? "YYYY-MM-DD HH:mm z" : "LLL z"
                }
              >
                {activeChallenge.startTime}
              </Moment>
              <br />
              <b>End time:</b>{" "}
              <Moment
                format={
                  region === Region.EUROPE ? "YYYY-MM-DD HH:mm z" : "LLL z"
                }
              >
                {activeChallenge.endTime}
              </Moment>
            </p>    
            
            {getLobbyInfo(
              activeChallenge,
              activeChallengeStatus,
              activeChallengeUserInfo,
              dispatch
            )}

          </div>
          <div className={css.gameRules}>
            <h4>Game Rules</h4>
            <div className={css.rule}>
              <img
                src={"/images/extracurricular/pictograms/clock.svg"}
                alt={""}
                width={20}
                height={20}
              />{" "}
              <p>
                {getActiveChallengeStatus(
                  activeChallenge,
                  activeChallengeStatus,
                  activeChallengeUserInfo
                )}
              </p>
            </div>

            <div className={css.rule}>
              <img
                src={"/images/extracurricular/pictograms/golf.svg"}
                alt={""}
                width={20}
                height={20}
              />
              <p>
                Course type : <b>18 Holes</b>
                <br />
                Game type : <b><span

          data-tooltip-id="tooltip">Net Stableford&nbsp;&nbsp;&#x2139;</span></b>
                <Tooltip id="tooltip" effect="solid" place="top" clickable>
                    <span>
                    To read more about 'Net Stableford' please read the{" "}
                    <a className={css.link} href="/faq#stableford"  rel="noopener noreferrer" style={{}}>
                        <u>faq</u>
                    </a>
                    .
                    </span>
                </Tooltip>
                <br />
                Player HCP : <b>{translateHcpType(activeChallenge.hcpType)}</b>
              </p>
            </div>

            <div className={css.rule}>
              <img
                src={"/images/extracurricular/pictograms/users.svg"}
                alt={""}
                width={20}
                height={20}
              />
              <p>
                Player count min : <b>0</b>
                <br />
                Player count max : <b>-</b>
              </p>
            </div>
          </div>
          {activeChallenge === null ? '' :
            <Rankings challengeStatus={activeChallenge.status} challengeError={activeChallenge.error} participants={activeChallenge.participants} maxParticipans={activeChallenge.maxParticipans} type={activeChallenge.type}/>
          }
        </section>
        <PricePool alt />
        <ContactCTA
            img="/images/extracurricular/layouts/contact-cta.webp"
            title={<>Do you want more&nbsp;?</>}
            desc="More Free and Buy-in Challenges available on wgc.gg & your own Challenges with friends, clients, club members to create!"
            btnLabel="Visit wgc.gg"
            btnLink="https://wgc.gg"
            />
      </main>
    </>
  );
}

function challengeStatusToText(status) {
  switch (status) {
    case ChallengeStatus.NOT_STARTED:
      return "Not started";
    case ChallengeStatus.RUNNING:
      return "Running";
    case ChallengeStatus.FINISHED:
      return "Finished";
    default:
      return "Unknown";
  }
}

function getActiveChallengeStatus(
  activeChallenge,
  activeChallengeStatus,
  activeChallengeUserInfo
) {
  if (activeChallengeStatus === "NOT_FOUND") {
    return "Not found";
  } else if (activeChallenge.error === true) {
    return "Error";
  } else {
    if (
      activeChallenge.status === ChallengeStatus.NOT_STARTED ||
      activeChallenge.status === ChallengeStatus.READY_TO_START
    ) {
      return countdownStart(activeChallenge);
    } else if (activeChallenge.status === ChallengeStatus.RUNNING) {
      return countdownEnd(activeChallenge);
    } else if (activeChallenge.status === ChallengeStatus.DONE) {
      return finishedLabel(activeChallenge, activeChallengeUserInfo);
    } else {
      return "Cancelled";
    }
  }
}

function getLobbyInfo(
  activeChallenge,
  activeChallengeStatus,
  activeChallengeUserInfo,
  dispatch
) {
  if (activeChallengeStatus === "NOT_FOUND") {
    // Case when the challenge is not found
    return notFound();
  } else if (activeChallenge.error === true) {
    // Case when there's an error in the active challenge
    return challengeError(activeChallenge, dispatch);
  } else if (
    activeChallenge.status === ChallengeStatus.NOT_STARTED ||
    activeChallenge.status === ChallengeStatus.READY_TO_START ||
    activeChallenge.status === ChallengeStatus.RUNNING
  ) {
    // Case when the challenge is not started, ready to start, or running
    return lobbyInfo(
      activeChallenge,
      activeChallengeUserInfo,
      activeChallenge.id,
      dispatch
    );
  } else if (activeChallenge.status === ChallengeStatus.DONE) {
    // Case when the challenge is done
    return finished(activeChallenge, activeChallengeUserInfo, dispatch);
  } else {
    // Default case (e.g., challenge is cancelled)
    return cancelled(activeChallenge, dispatch);
  }
}

function countdownStart(activeChallenge) {
  return (
    <>
      <span>Starts in: </span>
      <b>
        <CountdownTimer
          mykey="CountdownStart"
          started={activeChallenge.startTime}
          offset={0}
          renderer={ExtracurricularStartGameRenderer}
        />
      </b>
    </>
  );
}
function countdownEnd(activeChallenge) {
  return (
    <>
      <span>Ongoing. Ends in: </span>
      <b>
        <CountdownTimer
          mykey="CountdownStart"
          started={activeChallenge.endTime}
          offset={0}
          renderer={ExtracurricularEndGameRenderer}
        />
      </b>
    </>
  );
}

function finishedLabel(
  activeChallenge,
  activeChallengeUserInfo,
) {
  if (
    typeof activeChallengeUserInfo === "undefined" ||
    activeChallengeUserInfo.error === false
  ) {
    return "FINISHED";
  } else {
    return "ERROR";
  }
}

const getActiveChallengeUserInfo = (userId, activeChallenge) => {
  if (activeChallenge === undefined || userId === undefined) return undefined;
  return activeChallenge.participants.find((item) => item.userId === userId);
};

function notFound() {
  return (
    <>
      Challenge can not be found
      <Button to="/extracurricular" name="button">
        BACK
      </Button>
    </>
  );
}

function challengeError(activeChallenge, dispatch) {
  return (
    <> 
        This challenges has ended because of an error!
        <br /> 
        <small>{activeChallenge.errorDescription}</small>  
     
        <Button to="#"        
            name="button"
            onClick={() => dispatch(closeLobby(activeChallenge.id,'/extracurricular'))}>
            CLOSE LOBBY
        </Button>        
    </>
  );
}

function cancelled(activeChallenge, dispatch) {
  return (
    <>
        This challenges has been cancelled. Your money has been refunded.
        <Button to="#"       
          name="button"
          onClick={() => dispatch(closeLobby(activeChallenge.id,'/extracurricular'))}>
          CLOSE LOBBY
        </Button>
    </>
  );
}

function finished(activeChallenge, activeChallengeUserInfo, dispatch) {
  if (typeof activeChallengeUserInfo !== "undefined") {
    if (activeChallengeUserInfo.error === false) {
      return (
        <>
            The challenge is complete! Well played!
            <br />
            <small>The winners are displayed on the right side </small>
             
            <Button to="#"
              name="button"
              onClick={() => dispatch(closeLobby(activeChallenge.id,'/extracurricular'))} >
              CLOSE LOBBY
            </Button>     
        </>
      );
    } else {
      return (
        <>       
            The challenge is complete! Unfortunately you where disqualified
            because of the following error.
            <br /> 
            <small>{activeChallengeUserInfo.errorDescription}</small>
            <br />       
            <small>The winners are displayed on the right side</small>    
            <Button to="#"
              name="button"
              onClick={() => dispatch(closeLobby(activeChallenge.id,'/extracurricular'))} >
              CLOSE LOBBY
            </Button>              
        </>
      );
    }
  } else {
    return (
      <>
        The challenge is complete!
        <br />
        <small>The winners are displayed on the right side</small>
        <Button to="#"
            name="button"
            onClick={() => dispatch(closeLobby(activeChallenge.id,'/extracurricular'))} >
            CLOSE LOBBY
        </Button> 
      </>
    );
  }
}

function lobbyInfo(
  activeChallenge,
  activeChallengeUserInfo,
  challengeId,
  dispatch
) {
  console.log("activeChallengeUserInfo", activeChallengeUserInfo);
  return (
    <>
      {activeChallengeUserInfo !== undefined
        ? activeChallengeUserInfo.status === ParticipantStatus.JOINED &&
          activeChallenge.status === ChallengeStatus.RUNNING
          ? registerScoreButton(challengeId, activeChallenge, dispatch)
          : null
        : null}
     
      {activeChallengeUserInfo !== undefined &&
      activeChallengeUserInfo.status === ParticipantStatus.JOINED &&
      activeChallenge.type !== ChallengeType.FINAL &&
      (activeChallenge.status === ChallengeStatus.NOT_STARTED ||
        ((activeChallenge.status === ChallengeStatus.READY_TO_START ||
          activeChallenge.status === ChallengeStatus.RUNNING) &&
          activeChallenge.type === ChallengeType.FREE))
        ? leaveChallengeButton(activeChallenge.id,dispatch)
        : ""}

      {activeChallengeUserInfo !== undefined
        ? activeChallengeUserInfo.status === ParticipantStatus.DONE
          ? userFinished()
          : null
        : null}
      <div>       
          <span>{activeChallenge.numberOfParticipants} </span>     
          {activeChallenge.numberOfParticipants === 1
            ? "player registered"
            : "players registered"}  
      </div>
    </>
  );
}

function registerScoreButton(challengeId, activeChallenge, dispatch) {
  return (
    <div className="actions">
      <Button to="#"    
        name="button"
        onClick={() =>
          scoreDialog({
            reportScoreFunction: (id, score) =>
              dispatch(reportScore(id, score)),
            challengeId,
            activeChallenge,
          })
        }
      >
        {" "}
        Enter score
      </Button>
    </div>
  );
}

function userFinished() {
  return (
    <>    
        Great you are done!
        <br />      
        <small>You can see your score in the list</small>
    </>
  );
}

function leaveChallengeButton(id, dispatch) {
  return (
    <Link className={css.textLink} to={"#"} onClick={() => dispatch(leave(id,true))}>
      Unregister
    </Link>
  );
}

export const pricesHtml = ( prices ) => {

    let htmlString = "";
    if (prices.length >= 1) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_gold_ball.png">1st: ' + (prices[0].payoutType === PayoutType.CASH ? '$'+prices[0].payout : prices[0].text) + '</div>';
    }
    if (prices.length >= 2) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_silver_ball.png">2nd: ' + (prices[1].payoutType === PayoutType.CASH ? '$' + prices[1].payout : prices[1].text) +  '</div>';
    }
    if (prices.length >= 3) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_bronze_ball.png">3rd: ' + (prices[2].payoutType === PayoutType.CASH ? '$' + prices[2].payout : prices[2].text) +  '</div>';
    }

    if (prices.length > 3) {
       /* prices.splice(0, 3);

        htmlString = prices.map((item, index) => {
            return htmlString + '<div class="reward-container"><div></div>' + (index + 4) + 'th: $' + item.payout + '</div>';
        });*/
        htmlString = htmlString + '<small>Full breakdown in your lobby</small>';

    }

    htmlString = htmlString + 'All registered scores will receive a 50% discount card';

    return htmlString;
}
